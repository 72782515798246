//======================================================================
// mixin_ryu
//======================================================================

.pc {
  @include mq_max(768) {
    display: none;
  }
}

.sp {
  display: none;
  @include mq_max(768) {
    display: block;
  }
}
@mixin w1100 {
  max-width: 1130px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
@mixin sub_banner ($img_path_pc, $img_path_sp) {
  background: url($img_path_pc) no-repeat center;
  background-size: cover;
  height: 250px;
  width: 100%;
  margin-top: 55px;
  @include mq_max(768) {
    background: url($img_path_sp) no-repeat center;
    background-size: cover;
    margin-top: 70px;
  }

  h1 {
    font-size: 40px;
    text-align: center;
    line-height: 54px;
    color: $white;
    padding: 86px 0 10px;
    font-weight: bold;
    @include mq_max(768) {
      font-size: 26px;
      line-height: 32px;
      padding: 100px 0 5px;
    }
  }

  p {
    text-align: center;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    font-family: $font_2;
    @include mq_max(768) {
      font-size: 13px;
    }
  }
}
@mixin box-shadow-01 {
  box-shadow: 0 5px 40px #0000001A;
}
@mixin calendar {
  &__inner {
    max-width: 450px;
    @include pc {
      max-width: 528px;
    }
  }

  &__box {
    border: solid 1px $color_10;
    margin-bottom: 10px;
    @include pc {
      background: $white;
      margin-bottom: 20px;
    }
  }

  &__weeks {
    color: $white;
    background: $color_2;
    height: 30px;
    display: flex;
    font-size: 1.4em;
    font-weight: bold;
    @include pc {
      height: 46px;
    }
  }

  &__time {
    width: 100%;
    font-size: 1.4em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pc {
      font-size: 2.1em;
      font-weight: 500;
    }
  }

  &__sch {
    height: 30px;
    display: flex;
    @include pc {
      height: 46px;
    }

    &:not(:last-child) {
      border-bottom: solid 1px $color_10;
    }
  }

  &__plan {
    width: 30px;
    font-weight: 900;
    border-left: solid 1px $color_10;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pc {
      width: 45px;
      font-weight: 500;
      font-size: 1.9em;
    }
  }

  &__txt-wrap {
    margin: 10px 0 0;
    font-size: 1.4em;
    line-height: 1.428;
    @include pc {
      margin: 0;
    }
  }

  &__txt {
    display: flex;
    align-items: flex-start;
    @include pc {
      color: $white;
    }
  }

  .info__l-txt-wrap {
    display: flex;
    margin-bottom: 20px;
    @include mq_max(768) {
      display: inherit;
      margin-bottom: 10px;
    }

    .info__l-txt {
      width: 50%;
      @include mq_max(768) {
        width: 100%;
      }

      .info__txt {
        font-size: 16px;
        color: $color_13;
        line-height: 27px;
        @include mq_max(768) {
          font-size: 12px;
          line-height: 20px;
        }

        &.org {
          color: $color_9;
        }
      }
    }

    .calendar__txt-wrap {
      width: 50%;
      @include mq_max(768) {
        width: 100%;

        .calendar__txt {
          font-size: 12px;
        }
      }

      .calendar__txt {
        font-size: 14px;
        color: $color_13;
      }
    }
  }
}

.tel-01 {
  span {
    padding-left: 20px;
    background: url("../img/common/tel.svg") no-repeat left center;
  }
  @include mq_max(768) {
    border-radius: 2px;
    border: 1px solid $color_25;
    color: $color_25;
    text-align: center;
    padding: 10px;
  }
}
@mixin yellow {
  background: $color_15;
  padding: 40px 50px;
  margin-bottom: 30px;
  @include mq_max(768) {
    padding: 20px 15px;
    margin-bottom: 20px;
  }

  .h3_ttl_07__inner {
    margin: 0 auto 20px;
    padding: 8px 20px;
    display: table;
    @include mq_max(768) {
      display: block;
      margin: 0 auto 10px;
    }
  }

  .p_text_01 {}
}
@mixin text-box-01 {
  border: 1px solid #999999;
  border-radius: 2px;
  padding: 30px;
  @include mq_max(768) {
    padding: 20px 15px;
  }

  .text-box-inner {
    display: flex;
    @include mq_max(768) {
      display: inherit;
    }

    .left {
      border-right: 1px solid #CCCCCC;
      padding-right: 39px;
      @include mq_max(768) {
        padding-right: 0;
        border-bottom: 1px solid #CCCCCC;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }

    .right {
      padding-left: 39px;
      @include mq_max(768) {
        padding-left: 0;
      }
    }

    .left,
    .right {
      width: 50%;
      @include mq_max(768) {
        width: 100%;
      }

      .h3_ttl_08 {
        margin-bottom: 15px;
      }

      .p_text_01 {
        margin-bottom: 10px;
      }

      .list_01 {}
    }
  }
}

.c-footer-bar-01 {
  background: $color_3;

  .all-footer-bar-01-inner {
    width: 875px;
    margin: 0 auto;
    padding: 40px 0 50px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tel-box {
      &__txt-1 {
        font-size: 2.4em;
        line-height: 1.5;
        font-weight: 500;
      }

      &__txt-2 {
        font-size: 1.8em;
        line-height: 1.5;
        font-weight: 500;
      }

      &__txt-num {
        margin: 10px 0 0;
        font-size: 3.4em;
        line-height: 0.9;
        font-family: $font_2;
        color: $color_24;
        display: flex;
        align-items: center;

        &:before {
          content: url("../img/common/ico_tel.png");
        }
      }
    }

    .line-wrap {
      width: 440px;
      display: flex;

      &__txt {
        font-size: 1.6em;
        line-height: 1.5;
        font-weight: 500;
      }

      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__txt {
        margin: 10px 7px 0 0;
        font-size: 1.6em;
        line-height: 1.5;
      }

      &__img {
        max-width: 100%;
        flex-shrink: 0;
      }
    }
  }
}

.c-4-box {
  padding: 70px 0;
  @include mq_max(768) {
    padding: 40px 0;
  }

  .inner {
    @include w1100;

    .h3_ttl_04 {
      margin-bottom: 40px;
      @include mq_max(768) {
        margin-bottom: 20px;
      }
    }

    .box-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include mq_max(768) {
        display: inherit;
      }

      li {
        width: 530/1100 *100%;
        margin-bottom: 28px;
        @include mq_max(768) {
          width: 100%;
          margin-bottom: 12px;
        }

        &:nth-child(3) {
          margin-bottom: 0;
          @include mq_max(768) {
            margin-bottom: 12px;
          }
        }

        &:nth-child(4) {
          margin-bottom: 0;
        }

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.breadcrumbs {
  background: $color_3;
  @include mq_max(1100) {
    overflow: scroll;
  }

  .inner {
    padding: 10px;

    ul {
      display: flex;
      width: 1100px;

      li {
        margin-right: 10px;
        padding-right: 16px;
        background: url("../img/common/bc_icon.svg") no-repeat right center;
        //float: left;
        a {
          font-size: 11px;
          color: $white;
          text-decoration: underline;
          //font-weight: bold;
        }

        &:last-child {
          background: none;

          a {
            text-decoration: none;
            font-weight: normal;
          }
        }
      }
    }
  }
}
@mixin u_txt_1_a {
  color: #396092;
  text-decoration: underline;
  padding-left: 16px;
  position: relative;

  &::before {
    content: "";
    width: 7px;
    height: 7px;
    background: url("../img/ico/ico_listarr.svg") no-repeat center center/contain;
    position: absolute;
    left: 1px;
    top: 11px;
  }
}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//