//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: '../img/'; // コンテンツ幅
$content_width: 1200px;
$comp_width: 1100px; // SP時の左右の余白
$sp_side_padding: 20px; //=================================
// breakpoint
//=================================
// $pc_bp以上がPC表示
$pc_bp: 1025px; // $sp_bp以下がSP表示
$sp_bp: 767px; //=================================
// font
//=================================
$font_1: 'Noto Sans JP',
'ヒラギノ角ゴ Pro W3',
'Hiragino Kaku Gothic Pro',
'游ゴシック',
YuGothic,
sans-serif;
$font_2: Quicksand;

._quick {
  font-family: $font_2;
}

//font-weight
$fw_thin: 100;
$fw_light: 300;
$fw_regular: 400;
$fw_medium: 500;
$fw_semibold: 600;
$fw_bold: 700;
$fw_black: 900; //=================================
// color
//=================================
$black: #000;
$white: #fff;
$gray_30: #303030;
$gray_e6: #e6e6e6;
$gray_d5: #d5d5d5;
$gray_70: #707070;
$gray_9f: #9f9f9f;
$gray_a7: #A7A7A7;
$gray_b2: #B2B2B2;
$gray_a2: #A2A2A2;
$gray_f2: #F2F2F2;
$gray_d9: #D9D9D9;
$gray_cc: #ccc;
$gray_c: #ccc;
$color_1: #707070;
$color_2: #3FA037;
$color_3: #5D4F3E;
$color_4: #90BB36;
$color_5: #83786B;
$color_6: #4B3F30;
$color_7: #845F3D;
$color_8: #FAF7F2;
$color_9: #EC835E;
$color_10: #819A4F;
$color_11: #3BA2DE;
$color_12: #FCEFAC;
$color_13: #616752;
$color_14: #C1DDB9;
$color_15: #FBF7C7;
$color_16: #D7EDF5;
$color_17: #8A9178;
$color_18: #94C74C;
$color_19: #58AFE3;
$color_20: #947351;
$color_21: #DA6B08;
$color_22: #5A5249;
$color_23: #5D9D46;
$color_24: #FFDB0C;
$color_25: #3EA036;
$color_26: #FFCB07;
$color_27: #4A7E37;
$color_28: #AC835D;
$color_29: #DBD0C0;
$color_30: #EFEFED;
$color_31: #CACDC2;
$color_32: #DBDAD2;
$color_33: #EEE9E0;
$color_34: #DCDED6;
$color_35: #20B2E4;
$color_36: #6CBC09;
$color_37: #f7dc2e;
$color_38: #544E42;
$color_39: #AB916C;
$color_40: #DECFBD;
$color_41: #F3EEE4;
$color_42: #D9CFC3;
$color_43: #FFFCE6;
$color_44: #DBD0BF;
$color_60: #FDF9DA;
$color_61: #61B15B;
$color_62: #FEDB0C;
$color_63: #396092;
$color_64: #EFE9DF;
$color_65: #FFFCF0;
$color_66: #51A5D6;
$color_67:#DE6787;
//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000); // In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045); // Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275); // In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);