@import 'variable';
@import 'mixin';
@import 'mixin_ryu';
/* ======================================================================
 p_404
====================================================================== */
.l_mv {
  display: none;
}

.p_404 {
  background: $color_8;
}

.l_banner_01 {
  @include sub_banner("../img/404/banner01-pc.jpg","../img/404/banner01-sp.jpg");
}

.breadcrumbs {
  //display: none;
}

.s01 {
  margin: 70px auto;
  @include mq_max(768) {
    margin: 40px auto;
  }

  .inner {
    @include w1100;

    .box_wp {
      background: $white;
      padding: 50px;
      position: relative;
      @include mq_max(768) {
        padding: 20px 15px;
      }

      .logo {
        position: absolute;
        left: 50px;
        top: 50px;
        @include mq_max(768) {
          position: inherit;
          left: inherit;
          top: inherit;
          margin-bottom: 20px;
        }

        img {
          @include mq_max(768) {
            display: block;
            margin: 0 auto;
            max-width: 55px;
          }
        }
      }

      .no-page {
        padding-left: 84px;
        display: flex;
        flex-direction: row-reverse;
        @include mq_max(768) {
          padding-left: 0;
          display: inherit;
        }

        .left {
          max-width: 210px;
          @include mq_max(768) {
            max-width: 100%;
            margin-bottom: 20px;
          }

          img {
            width: 100%;
          }
        }

        .right {
          padding: 0 30px;
          @include mq_max(768) {
            padding: 0;
          }

          .list_01 {
            margin: 20px 0;
            @include mq_max(768) {
              margin: 15px 0;
            }
          }

          .u_txt_1 {
            a {
              @include u_txt_1_a;
            }
          }
        }
      }
    }

    .btn_06 {
      margin: 20px auto 0;
    }
  }
}